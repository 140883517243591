<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton text="Nova restrição" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="descricao"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        search
      >
        <template v-slot:idrestricao="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idrestricaocliente]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { RESTRICAO_CLIENTE_REMOVE } from '@/store/actions/restricaoCliente'

export default {
  name: 'RestricaoClienteTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'ID Restrição ERP', value: 'id_restricao_erp' },
      { text: 'Nome', value: 'descricao' },
      {
        text: 'Opções',
        value: 'idrestricao',
        clickable: false,
        align: 'center'
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/product-restriction/list/${this.getFornecedorId}?fp=todos`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClick(item) {
      this.$router.push({
        path: `restricoes-cliente/${item.idrestricaocliente}`,
        query: {
          descricao: item.descricao,
          id_restricao_erp: item.id_restricao_erp
        }
      })
    },
    onNew() {
      this.$router.push('restricoes-cliente/novo')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão da restrição de cliente '${item.descricao}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idrestricaocliente]: true
      }
      this.$store
        .dispatch(RESTRICAO_CLIENTE_REMOVE, item.idrestricaocliente)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Restrição de cliente removida com sucesso'
          )
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idrestricaocliente]: false
          }
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.idrestricaocliente]: false
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
